import React from "react";
import { Link } from "gatsby";
import { FaPrint, FaFacebookF, FaTwitter, FaStar, FaHeart } from "react-icons/fa";
import { ImArrowDown } from "react-icons/im";
import { FacebookShareButton, TwitterShareButton } from "react-share";

const SocialMedia = ({ uri, includePrint, includeReview, includeRecipe, includeSave, savedValue, saveCallBack }) => {
  return (
    <>
      <div className="pt-1 pb-4 d-flex flex-wrap">
        {includeRecipe ? (
          <div className="me-1 mt-1">
            <Link to="#recipe">
              <div className="action-button text-center align-items-center">
                RECIPE <ImArrowDown />
              </div>
            </Link>
          </div>
        ) : (
          <></>
        )}
        {includeSave ? (
          <div className="me-1 mt-1" role="button" tabIndex={0} onKeyPress={saveCallBack} onClick={saveCallBack}>
            <div className="action-button text-center align-items-center">
              {savedValue ? (
                <>
                  SAVED <FaHeart className="saved-recipe-icon" />
                </>
              ) : (
                <>
                  SAVE <FaHeart />
                </>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {includeReview ? (
          <div className="me-1 mt-1">
            <Link to="#reviews" className="link-no-bold">
              <div className="action-button text-center align-items-center">
                REVIEWS <FaStar />
              </div>
            </Link>
          </div>
        ) : (
          <></>
        )}
        {includePrint ? (
          <div className="me-1 mt-1">
            <Link to="?print" className="link-no-bold">
              <div className="action-button text-center align-items-center">
                PRINT <FaPrint />
              </div>
            </Link>
          </div>
        ) : (
          <></>
        )}
        <FacebookShareButton url={uri} className="me-1 mt-1">
          <div className="action-button text-center align-items-center">
            SHARE <FaFacebookF />
          </div>
        </FacebookShareButton>
        <TwitterShareButton url={uri} className="me-1 mt-1">
          <div className="action-button text-center align-items-center">
            SHARE <FaTwitter />
          </div>
        </TwitterShareButton>
      </div>
    </>
  );
};

export default SocialMedia;
