import React from "react"
import { Row, Container, Col, DropdownButton, Dropdown, Breadcrumb } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import RecipeCard from "../components/recipeCard"
import SocialMedia from "../components/socialMedia"

const uri = "/menke-family-cookbook/"

const FamilyCookbookPage = ( { location, data : { images } } ) => {
  const searchQuery = location.state?.searchQuery

    var imgarr = []
    for (var img of images.edges) {
      imgarr[img.node.name] = img.node.childImageSharp.gatsbyImageData
    }
  
  const cookbook_sections = [
    { name: "Cookies", link: "cookie-recipes"},
    { name: "Pies", link: "pie-recipes"},
    { name: "Desserts", link: "dessert-recipes"},
    { name: "Cakes", link: "cake-recipes"},
    { name: "Bars", link: "bar-recipes"},
    { name: "Breads", link: "bread-recipes"},
    { name: "Soup", link: "soup-recipes"},
    { name: "Main Dishes", link: "main-dish-recipes"},
    { name: "Side Dishes", link: "side-dish-recipes"},
    { name: "Salads", link: "salad-recipes"},
    { name: "Non-Food Items", link: "non-food-item-recipes"},
    { name: "Beverages", link: "beverage-recipes"},
  ]

  const sideBarOptions = 
    <><div className="category-menu-top"><Link to="/menke-family-cookbook/#mfc-top">Family Cookbook</Link></div>
      {cookbook_sections.map( section => (
        <div key={section.link} className="d-flex category-menu-links ps-3">
          <Link to={'#'+section.link}>{section.name}</Link>
        </div>
        ))
      }
    </>


  const dropDownOptions = 
    <DropdownButton title="Cookbook Sections">
      <Dropdown.Item href="/menke-family-cookbook/#mfc-top">Top</Dropdown.Item>
      {cookbook_sections.map( section => (
        <Dropdown.Item href={'#'+section.link}>{section.name}</Dropdown.Item>
          ))
      }
    </DropdownButton>
  

  return (
  <Layout pageInfo={{ pageName: "menke-family-cookbook", searchQuery: searchQuery }} sideBarOptions={sideBarOptions} dropDownOptions={dropDownOptions} sideBarWidth="200px">
    <Seo title="Family Cookbook" keywords={[`cookbook`, `recipes`]} description="Our family cookbook shared online" 
        imageSrc="/cookbook2.jpg" imageWidth="958" imageHeight="718" imageAlt="Family cookbook" url={uri} />

      <Container fluid className="text-center">
      
      <Row className="justify-content-left my-3">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/cookbooks">Cookbooks</Breadcrumb.Item>
          <Breadcrumb.Item active>Family Cookbook</Breadcrumb.Item>
        </Breadcrumb>
        <div className="cookbook-anchor-top" id="mfc-top">&nbsp;</div>
      </Row>
      
      <Row>
      <Col className="pt-2">
        <h1>Menke Family Cookbook</h1>
      </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <div id="family-cookbook-opening-message" className="p-4 lg-font">
        Welcome to the Menke Family Cookbook! This cookbook contains recipes, photos, and memories 
            from the extended Menke family. The recipe below for Fresh Strawberry Jam was hand written my Luella Menke. <br/>
           Please enjoy our cookbook!
        </div>
      </Row>
      
      <Row>
        <div className="d-flex justify-content-center pt-0 pb-4">
            <SocialMedia uri={"https://www.iloveitrecipes.com"+uri} position="top"/>
        </div>
      </Row>
      <Row>
        <Col md>
          <GatsbyImage image={imgarr["luella"]} alt="Luella" /><br/>Luella
        </Col>
        <Col md>
          <RecipeCard
              title="Fresh Strawberry Jam"
              uri="/recipes/view/fresh-strawberry-jam/"
              image={imgarr["fresh_strawberry_jam"]}
              imageAlt="Fresh Strawberry Jam"
              excerpt="Luella’s Recipe Card for Strawberry Jam"
            />
        </Col>
      </Row>
      <Row className="p-5">
        <div className="cookbook-anchor" id="cookie-recipes">&nbsp;</div>
        <h2>Cookie Recipes</h2>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Cry Babies Recipe"
              uri="/recipes/view/cry-babies/"
              image={imgarr["recipe_cry_babies"]}
              imageAlt="Cry Babies Recipe"
              image2={imgarr["sugar-cookie3"]}
              imageAlt2="Sugar Cookies with Coffee"
              excerpt="Provided by Katie P."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Molasses Cookies Recipe"
              uri="/recipes/view/molasses-cookies/"
              image={imgarr["gingerbread_boy"]}
              imageAlt="Molasses Cookies Recipe"
              excerpt="My mother used to make these molasses cookies for me every Christmas when I was a child and she some years she still does. I am allergic to raw eggs so my mother would make this so I could eat the raw cookie dough. She thought is was unfair that my brother and sister could always eat the cookie dough, so she made these.<br/><br/>Steve M."
            />
        </Col>
        <Col md>
        <RecipeCard
              title="Pumpkin Cookie Recipe"
              uri="/recipes/view/pumpkin-cookies/"
              image={imgarr["pumpkin_cookies"]}
              imageAlt="Pumpkin Cookies"
              excerpt="Pumpkin cookies are a Thanksgiving tradition.<br/><br/>Steve M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Rolled or Drop Sugar Cookies Recipe"
              uri="/recipes/view/rolled-or-drop-sugar-cookies/"
              image={imgarr["drop_cookies"]}
              imageAlt="Rolled Cookies"
              excerpt="Provided by Joan M."
            />
        </Col>
        <Col md>
        <RecipeCard
              title="Peanut Butter Cookies Recipe"
              uri="/recipes/view/peanut-butter-cookies/"
              image={imgarr["peanut-butter-cookie2"]}
              imageAlt="Peanut Butter Cookies"
              excerpt="Provided by Joan M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Oatmeal Raisin Cookies Recipe"
              uri="/recipes/view/oatmeal-raisin-cookies/"
              image={imgarr["oatmeal-raisin-cookies"]}
              imageAlt="Oatmeal Raisin Cookies"
              excerpt="Provided by Joan M."
            />
        </Col>
        <Col md>
        <RecipeCard
              title="Joan’s Molasses Cookies Recipe"
              uri="/recipes/view/molasses-cookies-2/"
              image={imgarr["molasses-cookie"]}
              imageAlt="Molasses Cookies"
              excerpt="Provided by Joan M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Chocolate Peanut Butter Cookies Recipe"
              uri="/recipes/view/chocolate-peanut-butter-cookies/"
              image={imgarr["chocolate-peanut-cookie-2"]}
              imageAlt="Chocolate Peanut Butter Cookies"
              excerpt="Provided by Sandy M."
            />
        </Col>
        <Col md>
        <RecipeCard
              title="Spritz Cookies Recipe"
              uri="/recipes/view/spritz-cookies/"
              image={imgarr["spritz-cookies"]}
              imageAlt="Spritz Cookies"
              excerpt="Spritz cookies are a Christmas tradition.<br/><br/>Provided by Rosie M."
            />
        </Col>
      </Row>

      <Row className="p-5">
        <div className="cookbook-anchor" id="pie-recipes">&nbsp;</div>
        <h2>Pie Recipes</h2>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Grandma Menke’s Raisin Cream Pie Recipe"
              uri="/recipes/view/grandma-menkes-raisin-cream-pie/"
              image={imgarr["recipe_raisin_cream_pie-cards"]}
              imageAlt="Raisin Cream Pie Recipe Cards"
              image2={imgarr["meringue-pie2"]}
              imageAlt2="Raisin Cream Pie Recipe"
              excerpt="Lee's favorite!<br/><br/>Provided by Katie P."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Terri’s Fabulous Pecan Pie Recipe"
              uri="/recipes/view/terris-fabulous-pecan-pie/"
              image={imgarr["pecan_pie"]}
              imageAlt="Pecan Pie"
              excerpt="Provided by Terri"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Berry Pie Recipe"
              uri="/recipes/view/berry-pie/"
              image={imgarr["strawberry-pie"]}
              imageAlt="Strawberry Pie"
              excerpt="Provided by Joan M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Lemon Pie Recipe"
              uri="/recipes/view/lemon-pie/"
              image={imgarr["lemon-pie2"]}
              imageAlt="Lemon Pie"
              excerpt="Provided by Joan M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Joan's Pecan Pie Recipe"
              uri="/recipes/view/pecan-pie/"
              image={imgarr["pecan-pie3"]}
              imageAlt="Pecan Pie"
              excerpt="Provided by Joan M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Brian’s Pecan Pie Recipe"
              uri="/recipes/view/pecan-pie-2/"
              image={imgarr["pecan-pie"]}
              imageAlt="Pecan Pie"
              excerpt="Provided by Brian M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="4-2-1 Pie Crust Recipe"
              uri="/recipes/view/4-2-1-pie-crust/"
              image={imgarr["pie-crust5"]}
              imageAlt="Pie Crust"
              excerpt="Provided by Joan M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Rosie’s Pie Crust Recipe"
              uri="/recipes/view/pie-crust/"
              image={imgarr["pie-crust3"]}
              imageAlt="Pie Crust"
              excerpt="Provided by Rosie M."
            />
        </Col>
      </Row>

      <Row className="p-5">
        <div className="cookbook-anchor" id="dessert-recipes">&nbsp;</div>
        <h2>Dessert Recipes</h2>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Rhubarb or Apple Crisp Recipe"
              uri="/recipes/view/rhubarb-or-apple-crisp/"
              image={imgarr["apple-crisp-1024x765"]}
              imageAlt="Rhubarb or Apple Crisp"
              excerpt="This is a classic, simple recipe for apple or rhubarb crisp. It won’t last very long. Don’t forget the vanilla ice cream. It’s an old Menke family tradition!<br/><br/>Provided by Joan M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Streuseled Apples Recipe"
              uri="/recipes/view/streuseled-apples/"
              image={imgarr["apple-streusel2"]}
              imageAlt="Apple Streusel"
              excerpt="Provided by Joan M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Baked Apples Recipe"
              uri="/recipes/view/baked-apples/"
              image={imgarr["baked-apples"]}
              imageAlt="Baked Apples"
              excerpt="Provided by Joan M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Joan’s Caramel Rolls Recipe"
              uri="/recipes/view/caramel-rolls-2/"
              image={imgarr["caramel_rolls_with_nuts"]}
              imageAlt="Caramel Rolls"
              excerpt="Provided by Joan M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Easy Caramel Rolls Recipe"
              uri="/recipes/view/easy-caramel-rolls/"
              image={imgarr["caramel-rolls3"]}
              imageAlt="Caramel Rolls"
              excerpt="Provided by Sandy M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Turtle Brownies Recipe"
              uri="/recipes/view/turtle-brownies/"
              image={imgarr["turtle-brownies2"]}
              imageAlt="Brownies"
              excerpt="Provided by Sandy M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Sandy’s Fruit Pizza Recipe"
              uri="/recipes/view/fruit-pizza-2/"
              image={imgarr["fruit-pizza2"]}
              imageAlt="Fruit Pizza"
              excerpt="Provided by Sandy M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Pecan Pickups Recipe"
              uri="/recipes/view/pecan-pickups/"
              image={imgarr["pecan-pickup"]}
              imageAlt="Pecan Pickup"
              excerpt="Provided by Brian M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Pumpkin Roll Recipe"
              uri="/recipes/view/pumpkin-roll/"
              image={imgarr["pumpkin-roll2"]}
              imageAlt="Pumpkin Roll"
              excerpt="Provided by Sandy M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Crumb Rhubarb Dessert Recipe"
              uri="/recipes/view/crumb-rhubarb-dessert/"
              image={imgarr["crumb-rhubarb"]}
              imageAlt="Rhubarb Dessert"
              excerpt="Provided by Sandy M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Peanut Butter Freezer Dessert Recipe"
              uri="/recipes/view/peanut-butter-freezer-dessert/"
              image={imgarr["peanut-butter-dessert"]}
              imageAlt="Peanut Butter Dessert"
              excerpt="Provided by Sandy M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Salted Nut Rolls Recipe"
              uri="/recipes/view/salted-nut-rolls/"
              image={imgarr["salted-nut-roll2"]}
              imageAlt="Salted Nut Roll"
              excerpt="Provided by Sandy M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Old Dutch Caramel Corn Recipe"
              uri="/recipes/view/old-dutch-caramel-corn/"
              image={imgarr["caramel-corn"]}
              imageAlt="Caramel Corn"
              excerpt="Provided by Steve"
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Caramel Chocolate Clusters Recipe"
              uri="/recipes/view/caramel-chocolate-clusters/"
              image={imgarr["walnuts"]}
              imageAlt="Walnuts"
              excerpt="Provided by Sandy M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Crispy Mallow Nests Recipe"
              uri="/recipes/view/crispy-mallow-nests/"
              image={imgarr["m-and-m2"]}
              imageAlt="m and m's"
              excerpt="Provided by Sandy M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Jean’s Dirt Cups Recipe"
              uri="/recipes/view/jeans-dirt-cups/"
              image={imgarr["gummy-worms"]}
              imageAlt="Gummy worms"
              excerpt="Provided by Sandy M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Julie’s Krunchy Karmel Korn Mix Recipe"
              uri="/recipes/view/julies-krunchy-karmel-korn-mix/"
              image={imgarr["caramel-corn3"]}
              imageAlt="Caramel Corn"
              excerpt="Provided by Steve"
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Peanut Clusters Recipe"
              uri="/recipes/view/peanut-clusters/"
              image={imgarr["chocolate-nut-cluster2"]}
              imageAlt="Chocolate Nut Cluster"
              excerpt="Provided by Sandy M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Spiced Mixed Nuts Recipe"
              uri="/recipes/view/spiced-mixed-nuts/"
              image={imgarr["mixed-nuts"]}
              imageAlt="Mixed Nuts"
              excerpt="Provided by Steve"
            />
        </Col>
      </Row>

      <Row className="p-5">
        <div className="cookbook-anchor" id="cake-recipes">&nbsp;</div>
        <h2>Cake Recipes</h2>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Carrot Cake Recipe"
              uri="/recipes/view/carrot-cake/"
              image={imgarr["carrot_cake"]}
              imageAlt="Carrot Cake"
              excerpt="Provided by Joan M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Cherry Coffee Cake Recipe"
              uri="/recipes/view/cherry-coffee-cake/"
              image={imgarr["cherry-coffee-cake2"]}
              imageAlt="Cherry Coffee Cake"
              excerpt="Provided by Joan M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Dirt Cake Recipe"
              uri="/recipes/view/dirt-cake/"
              image={imgarr["dirt-cake2"]}
              imageAlt="Oreo Cake"
              excerpt="Provided by Steve"
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Popcorn Cake Recipe"
              uri="/recipes/view/popcorn-cake/"
              image={imgarr["gum-drops2"]}
              imageAlt="Gum Drops"
              excerpt="Provided by Steve"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Pumpkin Spice Cake Recipe"
              uri="/recipes/view/pumpkin-spice-cake/"
              image={imgarr["pumpkin-spice-cake2"]}
              imageAlt="Pumpkin Spice Cake"
              excerpt="Provided by Sandy M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Pumpkin Spice Cake Cream Cheese Frosting Recipe"
              uri="/recipes/view/pumpkin-spice-cake-cream-cheese-frosting/"
              image={imgarr["frosting4"]}
              imageAlt="Frosting"
              excerpt="Provided by Sandy M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Spice Cake Recipe"
              uri="/recipes/view/spice-cake/"
              image={imgarr["spice-cake"]}
              imageAlt="Spice Cake"
              excerpt="Provided by Sandy M."
            />
        </Col>
      </Row>

      <Row className="p-5">
        <div className="cookbook-anchor" id="bar-recipes">&nbsp;</div>
        <h2>Bar Recipes</h2>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Pumpkin Bars Recipe"
              uri="/recipes/view/pumpkin-bars/"
              image={imgarr["pumpkin-bars"]}
              imageAlt="Pumpkin Bars"
              excerpt="Provided by Sandy M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Sandy’s Pumpkin Bars Recipe"
              uri="/recipes/view/pumpkin-bars-2/"
              image={imgarr["pumpkin-spice-bars2"]}
              imageAlt="Pumpkin Spice Bars"
              excerpt="Provided by Sandy M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Cream Cheese Frosting Recipe"
              uri="/recipes/view/cream-cheese-frosting/"
              image={imgarr["cream-cheese-frosting"]}
              imageAlt="Cream Cheese Frosting"
              excerpt="Provided by Sandy M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Rice Krispy Bars Recipe"
              uri="/recipes/view/rice-krispy-bars/"
              image={imgarr["rice-krispie-bars2"]}
              imageAlt="Rice Krispie Bars"
              excerpt="Provided by Steve"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Sour Cream Raisin Bars Recipe"
              uri="/recipes/view/sour-cream-raisin-bars/"
              image={imgarr["oatmeal-raisin"]}
              imageAlt="Oatmeal Raisin Bars"
              excerpt="Provided by Joan M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="One Minute Fudge Frosting Recipe"
              uri="/recipes/view/one-minute-fudge-frosting/"
              image={imgarr["chocolate-cake2"]}
              imageAlt="Chocolate Cake"
              excerpt="Provided by Sandy M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Nut Goodie Bar Recipe"
              uri="/recipes/view/nut-goodie-bar/"
              image={imgarr["nut-goodie-bar2"]}
              imageAlt="Nut Goodie Bars"
              excerpt="Provided by Rosie M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="O’Henry Bars Recipe"
              uri="/recipes/view/ohenry-bars/"
              image={imgarr["ohenry-bars"]}
              imageAlt="O'Henry Bars"
              excerpt="Provided by Sandy M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Tammy’s Rice Krispies Caramel Bars Recipe"
              uri="/recipes/view/tammys-rice-krispies-caramel-bars/"
              image={imgarr["rice-krispy-bars3"]}
              imageAlt="Rice Krispie Bars"
              excerpt="Provided by Sandy M."
            />
        </Col>
      </Row>

      <Row className="p-5">
        <div className="cookbook-anchor" id="bread-recipes">&nbsp;</div>
        <h2>Bread Recipes</h2>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="3-Day Buns Recipe"
              uri="/recipes/view/3-day-buns/"
              image={imgarr["recipe_card-3-day-buns"]}
              imageAlt="recipe card 3 day buns"
              image2={imgarr["buns"]}
              imageAlt2="buns"
              excerpt="Other family recipes have been mostly translated or explained so they make sense to anyone, but not this one. Lu says, &quot;I had to figure out how to make them work from these instructions...&quot; Good luck to the brave one(s) who go to the trouble to attempt them. <br/><br/>Provided by Katie P."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Banana Quick Bread Recipe"
              uri="/recipes/view/banana-quick-bread/"
              image={imgarr["banana_quick_bread"]}
              imageAlt="Banana Quick Bread Recipe Card"
              image2={imgarr["banana-bread2"]}
              imageAlt2="Banana Quick Bread"
              excerpt="This was Luella’s favorite, as you can see with her own writing.<br/><br/>Provided by Luella Menke"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Cherry Nut Bread Recipe"
              uri="/recipes/view/cherry-nut-bread/"
              image={imgarr["cherry_nut_bread_recipe_card"]}
              imageAlt="Cherry Nut Bread Recipe Card"
              image2={imgarr["cherry-nut-bread"]}
              imageAlt2="Cherry Nut Bread"
              excerpt="Rosie M. often makes this bread over the holidays. Her mother, Mary Kay, wrote the below recipe card.<br/><br/>Provided by Rosie M."
            />
        </Col>
      </Row>
      <Row>
      <Col md>
        <RecipeCard
              title="Zucchini Pineapple Bread Recipe"
              uri="/recipes/view/zucchini-pineapple-bread/"
              image={imgarr["zucchini-bread2"]}
              imageAlt="zucchini bread"
              excerpt="Provided by Joan M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Banana Bread Recipe"
              uri="/recipes/view/banana-bread/"
              image={imgarr["banana_bread"]}
              imageAlt="Banana Bread"
              excerpt="Provided by Joan M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Rosie’s Overnight Buns Recipe"
              uri="/recipes/view/rosies-overnight-buns/"
              image={imgarr["3daybuns"]}
              imageAlt="overnight buns"
              excerpt="It isn’t a Menke holiday without some fresh homemade buns!<br/><br/>Provided by Rosie M."
            />
        </Col>
      </Row>

      <Row className="p-5">
        <div className="cookbook-anchor" id="soup-recipes">&nbsp;</div>
        <h2>Soup Recipes</h2>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Turkey Pasta Soup Recipe"
              uri="/recipes/view/turkey-pasta-soup/"
              image={imgarr["turkey-pasta-soup"]}
              imageAlt="Turkey Pasta Soup"
              excerpt="Turkey Pasta Soup has turned into a &quot;day after Thanksgiving&quot; tradition.<br/><br/>Provided by Steve"
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Cheese Soup Recipe"
              uri="/recipes/view/cheese-soup/"
              image={imgarr["cheese-soup"]}
              imageAlt="Cheese Soup"
              excerpt="Provided by Katie P."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Chicken Wild Rice Soup Recipe"
              uri="/recipes/view/chicken-wild-rice-soup/"
              image={imgarr["chicken-wild-rice-soup2"]}
              imageAlt="Chicken Wild Rice Soup"
              excerpt="Provided by Brian M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Split Pea Soup Recipe"
              uri="/recipes/view/split-pea-soup/"
              image={imgarr["pea-soup2"]}
              imageAlt="Pea Soup"
              excerpt="Provided by Brian M."
            />
        </Col>
      </Row>

      <Row className="p-5">
        <div className="cookbook-anchor" id="main-dish-recipes">&nbsp;</div>
        <h2>Main Dish Recipes</h2>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Quiche Recipe"
              uri="/recipes/view/quiche/"
              image={imgarr["quiche"]}
              imageAlt="Quiche"
              excerpt="This is a slightly modified from a recipe given to me my Romain, who was visiting me from France. Romain was a student intern at the St. Paul French Immersion elementary school in Minnesota in 2004. The original is one of his favorite family recipes and now it’s ours too.<br/><br/>Provided by Steve"
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Aunt Kathy’s Cranberry Chicken Recipe"
              uri="/recipes/view/aunt-kathys-cranberry-chicken/"
              image={imgarr["cranberry-chicken2"]}
              imageAlt="Cranberry Chicken"
              excerpt="Provided by Steve"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Oven-Fried Chicken Dressing Recipe"
              uri="/recipes/view/oven-fried-chicken-dressing/"
              image={imgarr["stuffing2"]}
              imageAlt="Stuffing"
              excerpt="Provided by Rosie M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Alex’s Parmesan Chicken Recipe"
              uri="/recipes/view/parmesan-chicken-2/"
              image={imgarr["parmesan-chicken2"]}
              imageAlt="Pamesan Chicken"
              excerpt="Provided by Alex M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Parmesan Baked Chicken Recipe"
              uri="/recipes/view/parmesan-baked-chicken/"
              image={imgarr["parmesan-chicken"]}
              imageAlt="Parmesan Chicken"
              excerpt="Provided by Steve"
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Chicken Sunday Dinner Recipe"
              uri="/recipes/view/chicken-sunday-dinner/"
              image={imgarr["chicken-and-rice4"]}
              imageAlt="Chicken with Rice"
              excerpt="Provided by Terri"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Jerry’s Fried Chicken Recipe"
              uri="/recipes/view/jerrys-fried-chicken/"
              image={imgarr["fried-chicken"]}
              imageAlt="Fried Chicken"
              excerpt="Provided by Joan M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Chicken with Rice Recipe"
              uri="/recipes/view/chicken-with-rice/"
              image={imgarr["chicken-and-rice2"]}
              imageAlt="Chicken with Rice"
              excerpt="Provided by Joan M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Dawn’s Lasagna Recipe"
              uri="/recipes/view/dawns-lasagna/"
              image={imgarr["lasagna-2"]}
              imageAlt="Lasagna"
              excerpt="Provided by Terri"
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Spaghetti Recipe"
              uri="/recipes/view/spaghetti/"
              image={imgarr["spaghetti2"]}
              imageAlt="Spaghetti"
              excerpt="I used to love it when my mother would make this spaghetti sauce. She would let it simmer for hours, it smelled great, and it was always delicious. - Steve M.<br/><br/>Provided by Rosie M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Spaghetti Pie Recipe"
              uri="/recipes/view/spaghetti-pie/"
              image={imgarr["spaghetti"]}
              imageAlt="Spaghetti"
              excerpt="Provided by Brian M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Garlic Shrimp with Pasta Recipe"
              uri="/recipes/view/garlic-shrimp-with-pasta/"
              image={imgarr["shrimp_pasta2"]}
              imageAlt="Shrimp Pasta"
              excerpt="Provided by Steve"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Meaty Chili Recipe"
              uri="/recipes/view/meaty-chili/"
              image={imgarr["chili6"]}
              imageAlt="Chili"
              excerpt="Provided by Steve"
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Deep Dish Pizza Recipe"
              uri="/recipes/view/deep-dish-pizza/"
              image={imgarr["pizza-slice2"]}
              imageAlt="Pizza Slice"
              excerpt="Provided by Steve"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Beef Burgundy Recipe"
              uri="/recipes/view/beef-burgundy/"
              image={imgarr["beef-burgundy2"]}
              imageAlt="Beef Burgundy"
              excerpt="Provided by Steve"
            />
        </Col>
        <Col md>
          <RecipeCard
              title="French Crepes Recipe"
              uri="/recipes/view/french-crepes/"
              image={imgarr["crepe"]}
              imageAlt="French Crepe"
              excerpt="Provided by Steve"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Hamburger Noodle Hotdish Recipe"
              uri="/recipes/view/hamburger-noodle-hotdish/"
              image={imgarr["cheesy-pasta"]}
              imageAlt="Cheesy Pasta"
              excerpt="Provided by Steve"
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Fry Fish Recipe"
              uri="/recipes/view/fry-fish/"
              image={imgarr["fried-fish2"]}
              imageAlt="Fried Fish"
              excerpt="Provided by Steve"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Beans el Rancho Recipe"
              uri="/recipes/view/beans-el-rancho/"
              image={imgarr["beans-elrancho2"]}
              imageAlt="Beans"
              excerpt="Provided by Mike R. (son of Joan M.)"
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Piggybake Pork Bake Recipe"
              uri="/recipes/view/piggybake-pork-bake/"
              image={imgarr["pork-bake"]}
              imageAlt="Pork Bake"
              excerpt="Provided by Mike R. (son of Joan M.)"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="20 Ingredients Recipe"
              uri="/recipes/view/20-ingredients/"
              image={imgarr["crockpot-20-ingredients"]}
              imageAlt="Crockpot with 20 ingredients"
              excerpt="Provided by Mike R. (son of Joan M.)"
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Hot Dish Recipe"
              uri="/recipes/view/hot-dish/"
              image={imgarr["hot-dish2"]}
              imageAlt="Hot Dish"
              excerpt="Provided by Mike R. (son of Joan M.)"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Meat Ball Stroganoff Recipe"
              uri="/recipes/view/meat-ball-stroganoff/"
              image={imgarr["meatball-stroganoff2"]}
              imageAlt="Meatball Stroganoff"
              excerpt="Provided by Mike R. (son of Joan M.)"
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Tuna Hot Dish Recipe"
              uri="/recipes/view/tuna-hot-dish/"
              image={imgarr["tuna-and-noodles2"]}
              imageAlt="Tuna Hot Dish"
              excerpt="Provided by Mike R. (son of Joan M.)"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Pizza Hot Dish Recipe"
              uri="/recipes/view/pizza-hot-dish/"
              image={imgarr["pizza-hot-dish"]}
              imageAlt="Pizza Hot Dish"
              excerpt="Provided by Mike R. (son of Joan M.)"
            />
        </Col>
        <Col md>
        <RecipeCard
              title="Favorite Cheese Meat Loaf Recipe"
              uri="/recipes/view/favorite-cheese-meat-loaf/"
              image={imgarr["meat-loaf6"]}
              imageAlt="Meat loaf"
              excerpt="Provided by Mike R. (son of Joan M.)"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Chili Recipe"
              uri="/recipes/view/chili/"
              image={imgarr["chili3"]}
              imageAlt="Chili"
              excerpt="Provided by Joan M."
            />
        </Col>
        <Col md>
        <RecipeCard
              title="Lookout Mountain Chili Recipe"
              uri="/recipes/view/chili-2/"
              image={imgarr["chili"]}
              imageAlt="Chili"
              excerpt="Provided by Dave L."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Pork Chops Recipe"
              uri="/recipes/view/pork-chops/"
              image={imgarr["pork-chop"]}
              imageAlt="Pork Chop"
              excerpt="Provided by Joan M."
            />
        </Col>
        <Col md>
        <RecipeCard
              title="Beef Stew Recipe"
              uri="/recipes/view/beef-stew/"
              image={imgarr["beef-stew-bowl2"]}
              imageAlt="Beef Stew Bowl"
              excerpt="Provided by Brian M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Beef Stroganoff Recipe"
              uri="/recipes/view/beef-stroganoff/"
              image={imgarr["stroganoff2"]}
              imageAlt="Stroganoff"
              excerpt="Provided by Brian M."
            />
        </Col>
        <Col md>
        <RecipeCard
              title="French Dip Sandwiches Recipe"
              uri="/recipes/view/french-dip-sandwiches/"
              image={imgarr["french-dip"]}
              imageAlt="French Dip Sandwhich"
              excerpt="Provided by Brian M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Broccoli and Rice Casserole Recipe"
              uri="/recipes/view/broccoli-and-rice-casserole/"
              image={imgarr["broccoli-and-rice"]}
              imageAlt="Broccoli and Rice"
              excerpt="Provided by Sandy M."
            />
        </Col>
        <Col md>
        <RecipeCard
              title="Favorite Meatloaf Recipe"
              uri="/recipes/view/favorite-meatloaf/"
              image={imgarr["meat-loaf4"]}
              imageAlt="Meatloaf"
              excerpt="Provided by Sandy M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
          <RecipeCard
              title="Meatballs Recipe"
              uri="/recipes/view/meatballs/"
              image={imgarr["meatballs-1"]}
              imageAlt="Meatballs"
              excerpt="Provided by Steve"
            />
        </Col>
      </Row>

      <Row className="p-5">
        <div className="cookbook-anchor" id="side-dish-recipes">&nbsp;</div>
        <h2>Side Dish Recipes</h2>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Cucumbers Recipe"
              uri="/recipes/view/cucumbers/"
              image={imgarr["cucumbers2"]}
              imageAlt="Cucumbers"
              excerpt="Provided by Rosie M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Cranberry Sauce Recipe"
              uri="/recipes/view/cranberry-sauce/"
              image={imgarr["cranberries"]}
              imageAlt="Cranberries"
              excerpt="This cranberry sauce has become another Thanksgiving tradition.<br/><br/>Provided by Steve"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Dressing Recipe"
              uri="/recipes/view/dressing/"
              image={imgarr["stuffing4"]}
              imageAlt="Stuffing"
              excerpt="Provided by Joan M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Oreida Potato Casserole Recipe"
              uri="/recipes/view/oreida-patato-casserole/"
              image={imgarr["potato-casserole2"]}
              imageAlt="Potato Casserole"
              excerpt="Provided by Steve"
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Wild Rice Recipe"
              uri="/recipes/view/wild-rice/"
              image={imgarr["wild-rice"]}
              imageAlt="Wild Rice"
              excerpt="Provided by Joan M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Red Lobster Biscuits Recipe"
              uri="/recipes/view/red-lobster-biscuits/"
              image={imgarr["biscuits"]}
              imageAlt="Biscuits"
              excerpt="Provided by Joan M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Braunschweiger Ball Recipe"
              uri="/recipes/view/braunschweiger-ball/"
              image={imgarr["braunswieiger"]}
              imageAlt="Braunswieiger"
              excerpt="Braunschweiger is a Christmas tradition for a few Menke’s. Rosie M. would make it every year.<br/><br/>Provided by Rosie M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Honey Chicken Wings Recipe"
              uri="/recipes/view/honey-chicken-wings/"
              image={imgarr["chicken-wings3"]}
              imageAlt="Chicken Wings"
              excerpt="Provided by Brian M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Cucumber Dill Dip Recipe"
              uri="/recipes/view/cucumber-dill-dip/"
              image={imgarr["cucumber-dip2"]}
              imageAlt="Cucumber Dip"
              excerpt="Provided by Sandy M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Ham Roll-Ups Recipe"
              uri="/recipes/view/ham-roll-ups/"
              image={imgarr["ham-roll"]}
              imageAlt="Ham Roll"
              excerpt="Provided by Brian M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Peggy’s Vegetable Dip Recipe"
              uri="/recipes/view/peggys-vegetable-dip/"
              image={imgarr["dip2"]}
              imageAlt="Dip"
              excerpt="Provided by Sandy M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Bean Bake Recipe"
              uri="/recipes/view/bean-bake/"
              image={imgarr["bean-bake2"]}
              imageAlt="Bean dinner in a Bowl"
              excerpt="Provided by Rosie M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Bread Stuffing Recipe"
              uri="/recipes/view/bread-stuffing/"
              image={imgarr["stuffing"]}
              imageAlt="Stuffing"
              excerpt="Thanksgiving isn't Thanksiving without the stuffing.<br/><br/>Provided by Rosie M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Cheesy Hash Browns Recipe (Cheesy Potatoes)"
              uri="/recipes/view/cheesy-hash-browns/"
              image={imgarr["cheesy-potatoes"]}
              imageAlt="Cheesy Potatoes"
              excerpt="Provided by Steve"
            />
        </Col>
      </Row>

      <Row className="p-5">
        <div className="cookbook-anchor" id="salad-recipes">&nbsp;</div>
        <h2>Salad Recipes</h2>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Kookie Salad Recipe"
              uri="/recipes/view/kookie-salad/"
              image={imgarr["kookie-salad2"]}
              imageAlt="Cool Whip Chocolate Dessert"
              excerpt="Provided by Sandy M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Bacon Cauliflower Salad Recipe"
              uri="/recipes/view/bacon-cauliflower-salad/"
              image={imgarr["bacon-cauliflower-salad"]}
              imageAlt="Bacon Cauliflower Salad"
              excerpt="Provided by Brian M."
            />
        </Col>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Pudding Fruit Salad Recipe"
              uri="/recipes/view/pudding-fruit-salad/"
              image={imgarr["pudding-fruit-salad2"]}
              imageAlt="Pudding Fruit Salad"
              excerpt="Provided by Sandy M."
            />
        </Col>
      </Row>

      <Row className="p-5">
        <div className="cookbook-anchor" id="non-food-item-recipes">&nbsp;</div>
        <h2>Non-Food Item Recipes</h2>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Soap Recipe"
              uri="/recipes/view/soap/"
              image={imgarr["soap2"]}
              imageAlt="Soap"
              excerpt="This soap recipe was used by Mary Kay, Rosie M.’s mother.<br/><br/>Provided by Rosie M."
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Playdough Recipe"
              uri="/recipes/view/playdough/"
              image={imgarr["playdough2"]}
              imageAlt="Playdough"
              excerpt="This playdough recipe was given to Brian M. and Lisa M. from Peggy Deyak, Rosie’s friend.<br/><br/>Provided by Steve"
            />
        </Col>
      </Row>

      <Row className="p-5">
        <div className="cookbook-anchor" id="beverage-recipes">&nbsp;</div>
        <h2>Beverages Recipes</h2>
      </Row>
      <Row>
        <Col md>
        <RecipeCard
              title="Hot Spiced Apple Cider Recipe"
              uri="/recipes/view/hot-spiced-apple-cider/"
              image={imgarr["hot_apple_cider"]}
              imageAlt="Hot Apple Cider"
              excerpt="Hot spice apple cider is a Halloween favorite.<br/><br/>Provided by Steve"
            />
        </Col>
        <Col md>
          <RecipeCard
              title="Fruit Smoothies Recipe"
              uri="/recipes/view/fruit-smoothies/"
              image={imgarr["fruit-smoothie"]}
              imageAlt="Fruit Smoothie"
              excerpt="Provided by Steve"
            />
        </Col>
      </Row>
    </Container>
  </Layout>
)
}


export default FamilyCookbookPage

export const familyCookbookQuery = graphql`
  query familyCookbookImages {
    images: allFile (filter: { sourceInstanceName: { eq: "family-cookbook-images" }}) {
      edges {
        node {
          dir
          ext
          sourceInstanceName
          name
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
  }
`

